import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1a790083"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "reset-success-dialog"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'success-dialog',

  setup(__props, {
    expose: __expose
  }) {
    const dialogVisible = ref(false);

    const handleConfirmClick = () => {
      dialogVisible.value = false;
    };

    __expose({
      dialogVisible
    });

    const router = useRouter();

    const handleToLogin = () => {
      dialogVisible.value = false;
      router.push('/login');
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dialog, {
        title: _ctx.$t('resetPassword.reset_success_title'),
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        center: "",
        "destroy-on-close": "",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: handleToLogin
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.success_to_login')), 1)]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('resetPassword.reset_success_mgs')), 1)]),
        _: 1
      }, 8, ["title", "modelValue"])]);
    };
  }

};