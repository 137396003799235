import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2cad5a30"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "bottom-copyright-content"
};
import { getCurrYear } from '@/utils/data-format.js';
export default {
  __name: 'bottom-copyright',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, " Copyright © " + _toDisplayString(_unref(getCurrYear)()) + " Shenzhen Comentropy Industry and Standard Innovation Service Center. All Rights Reserved. ", 1);
    };
  }

};