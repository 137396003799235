import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-17ffc7f2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "switch-lang"
};
import useLocaleStore from '@/store/modules/locales.js';
export default {
  __name: 'switch-lang',

  setup(__props) {
    const localeStore = useLocaleStore();

    const handleToToggleLang = () => {
      localeStore.toggleLocale();
    };

    return (_ctx, _cache) => {
      const _component_el_link = _resolveComponent("el-link");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_link, {
        class: "switch-text",
        type: "primary",
        onClick: handleToToggleLang
      }, {
        default: _withCtx(() => [_createTextVNode("中/EN")]),
        _: 1
      })]);
    };
  }

};