import { defineStore } from 'pinia'

const useResetpwdStore = defineStore('reset-pwd', {
  state: () => ({
    resetEmail: '',
    resetAccount: '',
    resetEmailVerifyCode: '',
    resetPasswordForm: {},
    resetInfo: {} //count、csrf_token
  }),
  actions: {
    changeResetAccount(account) {
      this.resetAccount = account
    },
    changeResetEmailVerifyCode(code) {
      this.resetEmailVerifyCode = code
    },
    changeResetPasswordFormAccount(account, code) {
      this.resetPasswordForm.account = account
      this.resetPasswordForm.code = code
    },
    changeResetInfo(info) {
      this.resetInfo = info
    }
  }
})

export default useResetpwdStore
