import request from '@/service/index'

const resetPwdApi = {
  findAccount: '/portal/password/recover',
  emailValidate: '/portal/password/validate',
  resetPassword: '/portal/password/reset'
}

export function findAccount(data) {
  return request.post({
    url: resetPwdApi.findAccount,
    data
  })
}

export function emailValidate(data) {
  return request.post({
    url: resetPwdApi.emailValidate,
    data
  })
}

export function resetPassword(data) {
  return request.put({
    url: resetPwdApi.resetPassword,
    data
  })
}
export function emailResend(data) {
  return request.post({
    url: '/portal/password/resend',
    data
  })
}
