import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3b4151e3"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "reset-password"
};
const _hoisted_2 = {
  class: "container-hearder"
};
const _hoisted_3 = {
  class: "container-box"
};
const _hoisted_4 = {
  class: "switch-lang"
};
const _hoisted_5 = {
  class: "bottom-copyright"
};
import FormLogo from '@/components/form-logo';
import SwitchLang from '@/components/switch-lang';
import BottomCopyright from '@/components/bottom-copyright';
import { ref } from 'vue';
import FindCount from './cpns/find-count.vue';
import EmailVerify from './cpns/email-verify.vue';
import ResetPwd from './cpns/reset-pwd.vue';
export default {
  __name: 'reset-password',

  setup(__props) {
    const currentModal = ref(1);

    const handleChangeModal = index => {
      currentModal.value = index;
      window.scrollTo(0, 0);
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(FormLogo))]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(FindCount, {
        onChangeModal: handleChangeModal
      }, null, 512), [[_vShow, currentModal.value === 1]]), _withDirectives(_createVNode(EmailVerify, {
        onChangeModal: handleChangeModal
      }, null, 512), [[_vShow, currentModal.value === 2]]), _withDirectives(_createVNode(ResetPwd, {
        onChangeModal: handleChangeModal
      }, null, 512), [[_vShow, currentModal.value === 3]])]), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(SwitchLang))]), _createElementVNode("div", _hoisted_5, [_createVNode(_unref(BottomCopyright))])]);
    };
  }

};