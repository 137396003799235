// 邮箱
export const reg_email = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
)
export const isEmailFormat = (str) => {
  return reg_email.test(str)
}

// 密码
// 由字母和数字组成，最少6位
export const reg_pass = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d).{6,}$/)
export const isPassFormat = (str) => {
  return reg_pass.test(str)
}
