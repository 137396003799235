import "core-js/modules/es.error.cause.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1a7a7ce6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "reset-pwd"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "hint"
};
const _hoisted_5 = {
  class: "highlight"
};
const _hoisted_6 = {
  class: "reset-action-wrap"
};
const _hoisted_7 = {
  class: "bottom-login"
};
import DecorateLine from '@/components/decorate-line';
import { useRouter } from 'vue-router';
import useResetpwdStore from '@/store/modules/reset-pwd.js';
import { reactive, ref } from 'vue';
import { resetPassword } from '@/service/reset-pwd/reset-pwd.js';
import { ElMessage } from 'element-plus';
import SuccessDialog from './success-dialog.vue';
import { isEmailFormat, reg_pass } from '@/utils/validateFormate.js';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'reset-pwd',

  setup(__props) {
    const {
      t
    } = useI18n();
    const resetpwdStore = useResetpwdStore();
    const router = useRouter();
    const formRef = ref();
    const passwordForm = reactive({
      pass: '',
      checkPass: ''
    });

    const handleResetPwdNext = () => {
      const data = {
        password: passwordForm.pass,
        account: resetpwdStore.resetPasswordForm.account,
        code: resetpwdStore.resetPasswordForm.code,
        csrf_token: resetpwdStore.resetInfo.csrf_token
      };
      resetPassword(data).then(res => {
        if (res.code == 200) {
          successResetRef.value.dialogVisible = true;
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    };

    const handleNextClick = () => {
      var _formRef$value;

      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(valid => {
        if (valid) {
          // 1. 注册请求
          handleResetPwdNext();
        }
      });
    };

    const handleToLogin = () => {
      router.push('/login');
    };

    const successResetRef = ref(null); // 密码校验

    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        // 密码长度不能小于5
        callback(new Error(t('register.form_rules.password.length')));
      } else {
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (value != passwordForm.pass) {
        // 两次输入的密码不一致
        callback(new Error(t('register.form_rules.confirm_password.no_equal')));
      } else {
        callback();
      }
    };

    const formRule = {
      pass: [{
        required: true,
        message: t('register.form_rules.password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass,
        trigger: ['blur']
      }],
      checkPass: [{
        required: true,
        message: t('register.form_rules.confirm_password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass2,
        trigger: ['blur']
      }]
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_link = _resolveComponent("el-link");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(DecorateLine), {
        multiple: 2
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('resetPassword.title')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.set_pwd_hint_head')), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(resetpwdStore).resetInfo.account), 1), _createTextVNode(" " + _toDisplayString(_ctx.$t('resetPassword.set_pwd_hint_tail')), 1)]), _createVNode(_component_el_form, {
        model: passwordForm,
        ref_key: "formRef",
        ref: formRef,
        "hide-required-asterisk": "",
        "label-position": "left",
        rules: formRule
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "pass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: passwordForm.pass,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => passwordForm.pass = $event),
            "show-password": "",
            autocomplete: "off",
            placeholder: _ctx.$t('register.password'),
            size: "large",
            onKeydown: _withKeys(handleNextClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "checkPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: passwordForm.checkPass,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => passwordForm.checkPass = $event),
            "show-password": "",
            autocomplete: "off",
            placeholder: _ctx.$t('register.confirm_password'),
            size: "large",
            onKeydown: _withKeys(handleNextClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: handleNextClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.next_btn')), 1)]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_link, {
        type: "primary",
        onClick: handleToLogin
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.to_login_btn')), 1)]),
        _: 1
      })]), _createVNode(SuccessDialog, {
        ref_key: "successResetRef",
        ref: successResetRef
      }, null, 512)]);
    };
  }

};