import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6af9fe5a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "find-account"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "hint"
};
const _hoisted_5 = {
  class: "hint"
};
const _hoisted_6 = {
  class: "captcha-wrapper"
};
const _hoisted_7 = {
  class: "img-wrap"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "new-btn-wrap"
};
const _hoisted_10 = {
  class: "top"
};
const _hoisted_11 = {
  class: "bottom-login"
};
import DecorateLine from '@/components/decorate-line';
import { ref, reactive, watch } from 'vue';
import { ElMessage } from 'element-plus';
import useUserStroe from '@/store/modules/user.js';
import useResetpwdStore from '@/store/modules/reset-pwd.js';
import { useRouter } from 'vue-router';
import { findAccount } from '@/service/reset-pwd/reset-pwd.js';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'find-count',
  emits: ['changeModal'],

  setup(__props, {
    emit
  }) {
    const {
      t
    } = useI18n();
    const formRef = ref();
    const accountForm = reactive({
      account: '',
      captcha_text: ''
    });
    const userStore = useUserStroe();
    const resetpwdStore = useResetpwdStore();
    const router = useRouter(); // 输入密码时才显示验证码

    const isLoadCaptcha = ref(false);
    watch(() => accountForm.account, newVal => {
      if (newVal) {
        isLoadCaptcha.value = true;
      }
    });
    watch(isLoadCaptcha, newVal => {
      if (newVal) {
        userStore.fetchCaptchaImage();
      }
    }); // 刷新验证码

    const handleRefreshVerifyImgClick = () => {
      userStore.fetchCaptchaImage();
    };

    const handleToLogin = () => {
      router.push('/login');
    };

    const handleLoginClick = () => {
      var _formRef$value;

      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(valid => {
        if (valid) {
          handleResetNext();
        }
      });
    }; // 下一步


    const handleResetNext = () => {
      // 发送请求 用户存在才下一步
      const data = {
        account: accountForm.account,
        captcha_text: accountForm.captcha_text,
        captcha_img: userStore.image_id
      };
      findAccount(data).then(res => {
        if (res.code == 200) {
          // resetpwdStore.changeResetAccount(res.result.account)
          resetpwdStore.changeResetInfo(res.result); // 邮箱验证码发送成功

          ElMessage({
            message: t('resetPassword.send_success'),
            type: 'success'
          });
          emit('changeModal', 2);
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
          userStore.fetchCaptchaImage();
        }
      });
    };

    const rules = {
      account: [// 用户名或邮箱不能为空
      {
        required: true,
        message: t('resetPassword.rules.username_email.required'),
        trigger: ['blur', 'change']
      }],
      captcha_text: [// 验证码不能为空
      {
        required: true,
        message: t('resetPassword.rules.captcha_text.required'),
        trigger: ['blur', 'change']
      }]
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(DecorateLine), {
        multiple: 2
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('resetPassword.title')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('resetPassword.hint.hint_text_head')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('resetPassword.hint.hint_text_tail')), 1), _createVNode(_component_el_form, {
        model: accountForm,
        ref_key: "formRef",
        ref: formRef,
        "hide-required-asterisk": "",
        "label-position": "left",
        rules: rules
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "account"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: accountForm.account,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => accountForm.account = $event),
            placeholder: _ctx.$t('resetPassword.form.username_email'),
            size: "large",
            onKeydown: _withKeys(handleLoginClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), isLoadCaptcha.value ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          prop: "captcha_text"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "verifi-box"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: accountForm.captcha_text,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => accountForm.captcha_text = $event),
                placeholder: _ctx.$t('resetPassword.form.captcha_text'),
                size: "large",
                onKeydown: _withKeys(handleLoginClick, ["enter"])
              }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
                src: _unref(userStore).image_url,
                alt: "",
                onClick: handleRefreshVerifyImgClick
              }, null, 8, _hoisted_8), _createVNode(_component_el_link, {
                type: "primary",
                onClick: handleRefreshVerifyImgClick
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.form.refresh_code')), 1)]),
                _: 1
              })])])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_el_button, {
        type: "primary",
        class: "next-btn",
        onClick: handleLoginClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.next_btn')), 1)]),
        _: 1
      })])])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_link, {
        type: "primary",
        onClick: handleToLogin
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.to_login_btn')), 1)]),
        _: 1
      })])]);
    };
  }

};