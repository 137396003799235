import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-71cd42de"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "email-verify"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "hint"
};
const _hoisted_5 = {
  class: "email"
};
const _hoisted_6 = {
  class: "hint"
};
const _hoisted_7 = {
  class: "verify-code"
};
const _hoisted_8 = {
  class: "resend"
};
const _hoisted_9 = {
  class: "reset-action-wrap"
};
const _hoisted_10 = {
  class: "bottom-login"
};
import DecorateLine from '@/components/decorate-line';
import { useRouter } from 'vue-router';
import { ref, onMounted, onUnmounted } from 'vue';
import useResetpwdStore from '@/store/modules/reset-pwd.js';
import { emailValidate } from '@/service/reset-pwd/reset-pwd.js';
import { ElMessage } from 'element-plus';
import { emailResend } from '@/service/reset-pwd/reset-pwd.js';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'email-verify',
  emits: ['changeModal'],

  setup(__props, {
    emit
  }) {
    const {
      t
    } = useI18n();
    const router = useRouter();
    const resetpwdStore = useResetpwdStore();

    const handleRegisterClick = () => {
      emit('changeModal', 2);
    };

    const handleToLogin = () => {
      router.push('/login');
    };

    const verifyCode = ref(''); // 未收到验证码，重新发送！

    const resentText = ref(t('register.not_received_email'));
    const isSend = ref(false);
    let totalTime = 60;
    let timer = '';

    const handleReVerifyEmail = () => {
      if (isSend.value) return;
      emailResend({
        user_id: resetpwdStore.resetInfo.user_id,
        email: resetpwdStore.resetInfo.email,
        csrf_token: resetpwdStore.resetInfo.csrf_token
      }).then(res => {
        if (res.code == 200) {
          // 邮箱验证码发送成功
          ElMessage({
            message: t('register.send_email_success'),
            type: 'success'
          });
          handleSetInterval();
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    }; // 处理定时器 倒计时


    const handleSetInterval = () => {
      if (isSend.value) return;
      isSend.value = true; // 发送验证码请求

      resentText.value = t('register.later_send_head') + totalTime + t('register.later_send');
      timer = setInterval(() => {
        totalTime = totalTime - 1;
        window.sessionStorage.setItem('registerTime', totalTime);
        resentText.value = t('register.later_send_head') + totalTime + t('register.later_send');

        if (totalTime < 0) {
          clearInterval(timer); // '未收到验证码，重新发送！'

          resentText.value = t('register.not_received_email');
          totalTime = 60;
          isSend.value = false;
        }
      }, 1000);
    };

    onMounted(() => {});
    onUnmounted(() => {
      if (timer) clearInterval(timer);
    }); // 上一步

    const handleResetpwdPreClick = () => {
      emit('changeModal', 1);
    };

    const handleNextClick = () => {
      if (verifyCode.value.length != '') {
        hangleResetpwdNextClick();
      } else {
        // 请输入邮箱验证码
        ElMessage({
          message: t('register.please_enter_captcha'),
          type: 'warning'
        });
      }
    };

    const hangleResetpwdNextClick = () => {
      const data = {
        account: resetpwdStore.resetInfo.account,
        code: verifyCode.value,
        csrf_token: resetpwdStore.resetInfo.csrf_token
      }; // 发起验证验证码是否正确的请求

      emailValidate(data).then(res => {
        if (res.code == 200) {
          resetpwdStore.changeResetPasswordFormAccount(res.result.account, data.code);
          emit('changeModal', 3);
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(DecorateLine), {
        multiple: 2
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('resetPassword.title')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.$t('register.hint_text_action_head')), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(resetpwdStore).resetInfo.account), 1), _createTextVNode(_toDisplayString(_ctx.$t('register.hint_text_action_tail')), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('register.please_enter_captcha')), 1), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_input, {
        modelValue: verifyCode.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => verifyCode.value = $event),
        maxlength: "6",
        onKeydown: _withKeys(handleNextClick, ["enter"])
      }, null, 8, ["modelValue", "onKeydown"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_link, {
        type: "primary",
        disabled: isSend.value,
        onClick: _cache[1] || (_cache[1] = $event => handleReVerifyEmail())
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(resentText.value), 1)]),
        _: 1
      }, 8, ["disabled"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_el_button, {
        onClick: handleResetpwdPreClick,
        class: "pre-btn"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.pre_btn')), 1)]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: handleNextClick,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.next_btn')), 1)]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_el_link, {
        type: "primary",
        onClick: handleToLogin
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('resetPassword.to_login_btn')), 1)]),
        _: 1
      })])]);
    };
  }

};