import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/img/logo.png';

const _withScopeId = n => (_pushScopeId("data-v-1474593b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "form-logo"
};
export default {
  __name: 'form-logo',
  props: {
    pedestalSize: {
      type: String,
      default: '140'
    },
    logoSize: {
      type: String,
      default: '90'
    }
  },

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "logo-pedestal",
        style: _normalizeStyle({
          width: __props.pedestalSize + 'px',
          height: __props.pedestalSize + 'px'
        })
      }, [_createElementVNode("img", {
        src: _imports_0,
        style: _normalizeStyle({
          width: __props.logoSize + 'px',
          height: __props.logoSize + 'px'
        }),
        alt: "",
        class: "uniAccount"
      }, null, 4)], 4)]);
    };
  }

};